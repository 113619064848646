import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  exports: [MatIconModule],
})
export class CustomMatIconsRegistratorModule {
  constructor(sanitizer: DomSanitizer, matIconRegister: MatIconRegistry) {
    matIconRegister.addSvgIcon(
      'eye-show',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/eyes-show.svg')
    );
    matIconRegister.addSvgIcon(
      'icon-password',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/password.svg')
    );
    matIconRegister.addSvgIcon(
      'icon-user',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/user.svg')
    );
    matIconRegister.addSvgIcon(
      'dashboard',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/dashboard.svg')
    );
    matIconRegister.addSvgIcon(
      'family-group',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/family-group.svg')
    );
    matIconRegister.addSvgIcon(
      'credential',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/credential.svg')
    );
    matIconRegister.addSvgIcon(
      'payments',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/payments.svg')
    );
    matIconRegister.addSvgIcon(
      'stethoscope',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/stethoscope.svg')
    );
    matIconRegister.addSvgIcon(
      'tramites',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/tramites.svg')
    );
    matIconRegister.addSvgIcon(
      'state',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/state.svg')
    );
    matIconRegister.addSvgIcon(
      'calendar',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/calendar.svg')
    );
    matIconRegister.addSvgIcon(
      'travel',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/travel.svg')
    );
    matIconRegister.addSvgIcon(
      'heart-coverage',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/heart-coverage.svg')
    );
    matIconRegister.addSvgIcon(
      'medical-coverage',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/medical-coverage.svg')
    );
    matIconRegister.addSvgIcon(
      'open',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/open.svg')
    );
    matIconRegister.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/close.svg')
    );
    matIconRegister.addSvgIcon(
      'service-cancellation',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/service-cancellation.svg')
    );
    matIconRegister.addSvgIcon(
      'notifications',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/notifications.svg                                                                                                                                                                                                                               '
      )
    );
    matIconRegister.addSvgIcon(
      'invoice',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/invoice.svg')
    );
    matIconRegister.addSvgIcon(
      'content-search',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/content-search.svg')
    );
    matIconRegister.addSvgIcon(
      'add',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/add.svg')
    );
    matIconRegister.addSvgIcon(
      'info-check',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/info-check.svg')
    );
    matIconRegister.addSvgIcon(
      'search',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/search.svg')
    );
    matIconRegister.addSvgIcon(
      'minus-circle',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/minus-circle.svg')
    );
    matIconRegister.addSvgIcon(
      'menu',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/menu.svg')
    );
    matIconRegister.addSvgIcon(
      'dni',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/dni.svg')
    );
    matIconRegister.addSvgIcon(
      '123',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/123.svg')
    );
    matIconRegister.addSvgIcon(
      'warning',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/alert.svg')
    );
    matIconRegister.addSvgIcon(
      'info',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/info.svg')
    );
    matIconRegister.addSvgIcon(
      'done',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/check.svg')
    );
    matIconRegister.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/check1.svg')
    );
    matIconRegister.addSvgIcon(
      'clip',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/clip.svg')
    );
    matIconRegister.addSvgIcon(
      'delete',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/delete.svg')
    );
    matIconRegister.addSvgIcon(
      'check-document',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/check-document.svg')
    );
    matIconRegister.addSvgIcon(
      'arrow',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/button-arrow.svg')
    );
    matIconRegister.addSvgIcon(
      'gps',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/gps.svg')
    );
    matIconRegister.addSvgIcon(
      'pdf',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pdf.svg')
    );
    matIconRegister.addSvgIcon(
      'share',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/share.svg')
    );
    matIconRegister.addSvgIcon(
      'phone',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/phone.svg')
    );
  }
}
