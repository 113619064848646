<div class="wrapper">
  <ng-container *ngIf="data.type === 'success'">
    <div class="center">
      <img src="assets/svg/check.svg" width="58" />
      <h1>
        Tu solicitud fue <br />
        registrada con éxito
      </h1>
      <p>
        En el plazo estimado de <strong>48 hs. hábiles</strong> <br />
        te estaremos respondiendo.
      </p>
      <p>
        Podés seguir el trámite <br />
        con el Nº <strong>{{ data.text }}.</strong>
      </p>
      <nobis-button [routerLink]="['/dashboard/mi-panel']" mat-dialog-close
        >ACEPTAR</nobis-button
      >
    </div>
  </ng-container>

  <ng-container *ngIf="data.type === 'error'">
    <img width="43" height="56" src="assets/svg/alert.svg" alt="" />
    <ng-container *ngIf="!!data.text; else genericMessage">
      <h1>{{ data.text }}</h1>
    </ng-container>
    <ng-template #genericMessage>      
      <h1>Hubo un error en tu <strong>solicitud</strong></h1>
      <p>Vuelve a intentarlo.</p>
    </ng-template>
    <nobis-button [routerLink]="['/dashboard/mi-panel']" mat-dialog-close >CONTINUAR</nobis-button>
  </ng-container>

  <ng-container *ngIf="data.type === 'more-info'">
    <img width="43" height="56" src="assets/svg/check-document.svg" alt="" />
    <div class="texto" [innerHTML]="data.text"></div>
    <nobis-button  mat-dialog-close >CONTINUAR</nobis-button>
  </ng-container>
</div>
