<ng-container *ngIf="!(loginError$ | async) as res; else loginError">
  <section [@fadeIn] id="login" class="relative">
    <nobis-loading *ngIf="loading"></nobis-loading>
    <form [formGroup]="form">
      <mat-form-field color="accent" hideRequiredMarker appearance="outline">
        <button
          class="pointer-event-none"
          mat-icon-button
          matPrefix
          [attr.aria-label]="' Selecciona un tipo de documento'">
          <mat-icon svgIcon="dni"></mat-icon>
        </button>
        <mat-select formControlName="documentType" placeholder="Tipo de documento">
          <ng-container *ngIf="typesDocument$ | async as typesDocuments">
            <mat-option
              *ngFor="let item of typesDocuments"
              [value]="item.id"
              (click)="typeSelected(item.value)">
              {{ item.value }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="form.controls['documentType'].hasError('required')">
          Seleccioná un tipo de documento
        </mat-error>
      </mat-form-field>

      <mat-form-field color="accent" hideRequiredMarker appearance="outline">
        <input
          matInput
          formControlName="document"
          type="number"
          pattern="[0-9]*"
          placeholder="Nº de {{ type }}" />
        <button
          class="pointer-event-none"
          mat-icon-button
          matPrefix
          [attr.aria-label]="'ingresa tu email'">
          <mat-icon svgIcon="123"></mat-icon>
        </button>
        <mat-error *ngIf="form.controls['document'].hasError('required')">
          Este campo es requerido
        </mat-error>
      </mat-form-field>

      <mat-form-field hideRequiredMarker color="accent" appearance="outline">
        <input
          placeholder="Contraseña"
          matInput
          formControlName="password"
          autocomplete="current-password"
          [type]="hide ? 'password' : 'text'" />
        <button
          mat-icon-button
          [color]="
            form.controls['password'].errors && form.controls['password'].touched
              ? 'warn'
              : 'accent'
          "
          matSuffix
          (click)="togglePasswordVisibility($event)"
          [attr.aria-label]="'Mostrar contraseña'"
          [attr.aria-pressed]="'Ocultar contrasena'">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <button mat-icon-button matPrefix class="pointer-event-none">
          <mat-icon svgIcon="icon-password"></mat-icon>
        </button>
        <mat-error *ngIf="form.controls['password'].hasError('required')">
          Este campo es requerido
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle formControlName="rememberDocument"
        >Recordar tipo y número de documento</mat-slide-toggle
      >
    </form>

    <nobis-button [disabled]="form.invalid" (click)="handleSubmitLogin()">INGRESAR</nobis-button>
    <div class="action">
      <a [routerLink]="['recupera-tu-contraseña']">OLVIDÉ MI CONTRASEÑA</a>
      <a [routerLink]="['registrate']">REGISTRARME</a>
    </div>
  </section>
</ng-container>

<ng-template #loginError let-error>
  <section id="login-error">
    <div class="wrapper">
      <img width="70" src="assets/svg/alert.svg" alt="" />
      <ng-container class="wrapper" *ngIf="loginError$ | async as error">
        <h1 [ngClass]="{ break: error.status === 'Validation Error' }">{{ error.message }}</h1>
        <p *ngIf="error.status === 'coverage-inactive'">
          Por favor
          <strong>
            ponete en contacto con Lili, <br />
            nuestra asistenta virtual,</strong
          >haciendo
          <b (click)="redirectLogin()"
            ><a
              href="https://api.whatsapp.com/send?phone=543513893211"
              target="_blank"
              rel="noopener noreferrer"
              >clic acá
            </a>
          </b>
        </p>
        <p *ngIf="error.status === 'Validation Error'">
          <strong>Volvé a intentarlo</strong>, haciendo <b (click)="redirectLogin()">clic acá</b>
        </p>

        <p *ngIf="error.code === 500">
          <strong>Volvé a intentarlo</strong>, haciendo <b (click)="redirectLogin()">clic acá</b>
        </p>
        <p *ngIf="error.status === 'cannot-login'">Te esperamos pronto.</p>

        <h1 *ngIf="error.status === 'cannot-login'">{{ error.message }}</h1>
      </ng-container>
    </div>
  </section>
</ng-template>
