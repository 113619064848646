import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, finalize } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { DocumentService } from 'src/app/core/services/documentType.service';
import { selectIsLogginError } from 'src/app/store/selectors/authentication.selectors';
import { LoginFacade } from './login.facade';

@Component({
  selector: 'nobis-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          stagger(100, animate('500ms ease-out', style({ opacity: 1 }))),
        ]),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private store = inject(Store);
  private facade = inject(LoginFacade);
  private documentService = inject(DocumentService);
  protected hide = true;
  protected loading: boolean = false;
  protected typesDocument$!: Observable<any>;
  protected loginError$ = this.store.select(selectIsLogginError).pipe(shareReplay(1));
  protected type = 'DNI';
  private destroy$ = new Subject<void>();
  protected form = this.fb.nonNullable.group({
    documentType: [2, [Validators.required]],
    document: ['', [Validators.required]],
    password: ['', [Validators.required]],
    rememberDocument: [false],
  });

  public ngOnInit(): void {
    this.typesDocument$ = this.documentService.getDocumentType();
    this.getLocalStorage();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected typeSelected(type: string) {
    this.type = type;
  }

  protected togglePasswordVisibility(event: Event): void {
    event?.stopPropagation();
    this.hide = !this.hide;
  }

  protected handleSubmitLogin() {
    if (this.form.invalid) return;

    this.loading = true;
    const formValue = this.form.getRawValue();
    this.getDocumentFromStorage(formValue);
    this.facade.submitLogin(formValue);

    this.facade.isAuthenticated
    .pipe(
      finalize(() => {        
        const redirectUrl = this.activatedRoute.snapshot.queryParams['r'];
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
          return;
        }

        this.router.navigateByUrl('dashboard/mi-panel');
    }),
      takeUntil(this.destroy$),
    )
    .subscribe();

    this.loginError$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((loginError: {code: number, status: string, message: string}) => {
      if (loginError) this.loading = false;
    });
  }

  private getDocumentFromStorage(payload: {
    documentType: number;
    document: string;
    password: string;
    rememberDocument: boolean;
  }): void {
    if (payload.rememberDocument) {
      localStorage.setItem('rememberDocument', JSON.stringify(payload.document));
      localStorage.setItem('rememberDocumentType', JSON.stringify(payload.documentType));
    } else {
      this.cleanLocalStorage();
    }
  }

  private cleanLocalStorage(): void {
    localStorage.removeItem('rememberDocument');
    localStorage.removeItem('rememberDocumentType');
  }

  private getLocalStorage(): void {
    const rememberDocument = localStorage.getItem('rememberDocument');
    const rememberDocumentType = localStorage.getItem('rememberDocumentType');
    if (rememberDocument !== null && rememberDocumentType !== null) {
      this.form.patchValue({
        documentType: JSON.parse(rememberDocumentType),
        document: JSON.parse(rememberDocument),
        rememberDocument: true,
      });
    }
  }
  protected redirectLogin() {
    this.facade.dispachDeleteErrorLogin();
  }
}
