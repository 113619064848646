import { Component, Input } from '@angular/core';

@Component({
  selector: 'nobis-required-element',
  templateUrl: './required-element.component.html',
  styleUrls: ['./required-element.component.scss']
})
export class RequiredElementComponent {
  @Input() status: 1 | 0 = 1;

  protected getColor(value: number) {
    return value === 1 ? '#E45454' : '#52B7A0';
  }
}
