import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nobis-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],

})
export class ModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { text: string, type: 'success' | 'error' | 'more-info' }) { }
}
