import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, first, switchMap } from 'rxjs/operators';
import { selectToken } from 'src/app/store/selectors/authentication.selectors';
import { AuthenticationService } from '../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/views/dashboard/dashboard-authorizations/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  private store = inject(Store);
  private authenticationService = inject(AuthenticationService);

  constructor(private dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return this.store.select(selectToken).pipe(
      first(),
      switchMap(token => {
        const clonedRequest = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next.handle(token ? clonedRequest : req).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401) this.authenticationService.logout();            
            if (err.status === 400 || err.status === 500) this.showModal(err?.error?.message);

            throw err;
          })
        );
      })
    );
  }

  protected showModal(message: string) {
      this.dialog.open(ModalComponent, {
        data: { text: message, type: 'error'}
      });
    }
}
