import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Affiliate, FamilyMember } from '../model/affiliate.interfaces';
import { Response } from '../model/response';

@Injectable({
  providedIn: 'root',
})
export class FamilyGroupService {
  private httpClient = inject(HttpClient);
  public getFamilyGroup(benGrId: number): Observable<FamilyMember[]> {
    const url = `${environment.apiUrl}affiliates/${benGrId}/group`;
    return this.httpClient.get<Response<FamilyMember[]>>(url).pipe(map(({ data }) => data));
  }
  public affiliateByBeneficiaryIdService(benId: number): Observable<Affiliate[]> {
    const url = `${environment.apiUrl}affiliates/${benId}`;
    return this.httpClient.get<Response<Affiliate[]>>(url).pipe(map(({ data }) => data));
  }

  public getDistrictsService(): Observable<any> {
    const url = `${environment.apiUrl}taxonomies/districts`;
    return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data));
  }
  public getLocalitiesService(): Observable<any> {
    const url = `${environment.apiUrl}taxonomies/localities`;
    return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data));
  }

  public updateDateContact(payload: Contacto, benId: string): Observable<any> {
    const url = `${environment.apiUrl}affiliates/${benId}/update/contact-info`;
    return this.httpClient.put<Response<any>>(url, payload).pipe(map(({ data }) => data));
  }

  public updateDateAddress(payload: Location, benId: string): Observable<any> {
    const url = `${environment.apiUrl}affiliates/${benId}/update/address`;
    return this.httpClient.put<Response<any>>(url, payload).pipe(map(({ data }) => data));
  }
}

export interface Contacto {
  email: string;
  phoneNumber: string;
  mobileNumber: string;
}
export interface Address {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface Location {
  floor: string;
  apartment: string;
  address: Address[];
}
